import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import campaign from "../assets/campaign.svg";
// import gift from '../assets/gift.svg';
import { CampaignManagerAPI } from "../util/ApiGateway/Api";

function Home() {
  const { schema, token } = useParams();

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [campaignName, setCampaignName] = useState();
  const [config, setConfig] = useState({});
  const [verify, setVerify] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await CampaignManagerAPI.get(
          `/verification/otp-config/${schema}/${token}`
        );
        console.log(data);
        setCampaignName(data?.data.name);
        setConfig({
          image: data.data.otp_conf?.image,
          button_color: data.data.otp_conf
            ? data.data.otp_conf["button-color"]
            : null,
          background_color: data.data.otp_conf
            ? data.data.otp_conf["background-color"]
            : null,
        });
        setVerify(data.data.verify);
        setLoading(false);
      } catch (error) {
        console.log(error.message);
        if (error?.response?.data?.message) {
          alert(error?.response?.data?.message);
          window.close();
        } else {
          alert(error.message);
          window.close();
        }
      }
    })();
  }, []);

  const verifyOTP = async () => {
    try {
      setSubmitLoading(true);
      const { data } = await CampaignManagerAPI.post(
        "/verification/verify-otp",
        {
          schema,
          otp: token,
        }
      );
      console.log(data);
      setVerify(true);
      setSubmitLoading(false);
    } catch (error) {
      console.log(error?.response?.data?.message);
      if (error?.response?.data?.message) {
        if (
          window.confirm(
            `${error?.response?.data?.message}. Want to try again?`
          )
        ) {
          setSubmitLoading(false);
        } else {
          window.close();
        }
      } else {
        if (window.confirm(`${error.message}. Want to try again?`)) {
          setSubmitLoading(false);
        } else {
          window.close();
        }
      }
    }
  };
  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%",
        }}
      >
        <ThreeDots
          height="100"
          width="100"
          radius="9"
          color="#4fa94d"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    );
  } else {
    return (
      <div
        className="body-wrapper"
        style={{ backgroundColor: config.background_color || "#fff" }}
      >
        <div className="content">
          {/* <img src={gift} alt='gift' className='gift' /> */}
          <img
            src={
              config.image
                ? process.env.REACT_APP_s3_cdn + config.image
                : campaign
            }
            alt="campaign"
            className="image"
          />
          <span className="title" style={{ color: "#000" }}>
            {campaignName}
          </span>
          {verify ? (
            <span className="verified-title">OTP Verified</span>
          ) : (
            <>
              {submitLoading ? (
                <>
                  <span className="sub-title">Verifying</span>

                  <ThreeDots
                    height="100"
                    width="100"
                    radius="9"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </>
              ) : (
                <>
                  <span className="sub-title">
                    Please click to verify yourself
                  </span>
                  <button
                    className="btn"
                    style={{
                      backgroundColor: config.button_color || "#4c8bf5",
                      color: "#fff",
                    }}
                    onClick={verifyOTP}
                  >
                    Verify
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default Home;
